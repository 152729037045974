<template>
  <div>
    <div v-if="isLoading"><OtherLoading /></div>
    <div v-else>
      <HeaderPanel
        title="E-Commerce"
        :filter="filter"
        placeholder="Search Shop Name, Market Place"
        @sidebar="sidebarFilter"
        @search="getListMarketPlace"
        :hasDropdown="false"
        routerPath=""
        :hideCreate="true"
        @btnMethods="addMarketPlace()"
      />
      <div class="bg-white border-red p-2 p-lg-3 mt-3">
        <b-table
          responsive
          striped
          hover
          :fields="fields"
          :items="items"
          :busy="isBusy"
          show-empty
          empty-text="No matching records found"
          class="mt-3"
        >
          <template v-slot:cell(status)="{ item }">
            <div class="text-left">
              <div
                class="d-flex align-items-center"
                :style="`color:${item.status ? '#27bd89' : 'red'}`"
              >
                <div
                  class="dot-status"
                  :style="`background-color:${item.status ? '#27bd89' : 'red'}`"
                ></div>
                <b>
                  {{ item.status ? "Connected" : "Not Connected" }}
                </b>
              </div>
              <div class="ml-4">
                <small style="color: #acacac">
                  Updated:
                  {{
                    $moment(item.update_date).format("DD MMM YYYY HH:mm:ss")
                  }}</small
                >
              </div>
              <div class="ml-4">
                <small style="color: #acacac">
                  Created:
                  {{
                    $moment(item.create_date).format("DD MMM YYYY HH:mm:ss")
                  }}</small
                >
              </div>
            </div>
          </template>
          <template v-slot:cell(action)="{ item }">
            <div class="row justify-content-center no-gutters">
              <div
                class="col-sm-4 col-12 cursor-pointer"
                @click="handleEdit(item)"
              >
                <font-awesome-icon icon="pen" class="mr-2" />
                <u>Edit</u>
              </div>

              <div
                class="col-sm-4 col-12 cursor-pointer"
                :style="`color:${!item.status ? '#27bd89' : 'red'}`"
                @click="marketplaceDisconnect(item)"
              >
                <font-awesome-icon
                  :icon="!item.status ? 'link' : 'unlink'"
                  class="mr-2"
                />
                <u>{{ !item.status ? "Reconnect" : "Disconnect" }}</u>
                <!-- <b-spinner class="align-middle ml-2"></b-spinner> -->
              </div>
            </div>
          </template>
          <template v-slot:cell(marketplace)="{ item }">
            <div class="d-flex justify-content-center align-items-center">
              <!-- :src="
                  require(`@/assets/icons/marketplace/${item.marketplace.toLowerCase()}.png`)
                " -->
              <img
                class="market-place-image-logo"
                :src="item.marketplaceUrl"
                alt="market place image"
              />
              <div class="ml-3 text-left">
                <div class="f-16">
                  <b> {{ item.marketplace }}</b>
                </div>
                <router-link :to="{ path: 'ecommerce/0' }">
                  <div class="text-log">VIEW LOG</div>
                </router-link>
              </div>
            </div>
          </template>
          <template v-slot:table-busy>
            <div class="text-center text-black my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong class="ml-2">Loading...</strong>
            </div>
          </template>
        </b-table>
        <Pagination
          @handleChangeTake="handleChangeTake"
          :pageOptions="pageOptions"
          :filter="filter"
          :rows="rows"
          @pagination="pagination"
        />
      </div>
      <SideBarFilter
        ref="sidebarFilter"
        :filter="filter"
        placeholder="Search Staff ID, Name"
        :hideStatusFilter="false"
        @searchAll="getMarketPlace"
        @clearFilter="clearFilter"
      >
        <template v-slot:checkbox-option>
          <b-form-group>
            <template #label>
              <b>Shopping Channel:</b><br />
              <b-form-checkbox
                v-model="shopping_SelectedAll"
                aria-describedby="flavours"
                aria-controls="flavours"
                @change="ShoppingToggleAll"
              >
                All
              </b-form-checkbox>
            </template>

            <template v-slot="{ ariaDescribedby }">
              <b-form-checkbox-group
                v-model="filter.shopping_channel"
                :options="shopping"
                textField="name"
                valueField="id"
                :aria-describedby="ariaDescribedby"
                class="ml-4"
                stacked
              ></b-form-checkbox-group>
            </template>
          </b-form-group>
          <b-form-group>
            <template #label>
              <b>Status:</b><br />
              <b-form-checkbox
                v-model="Status_SelectedAll"
                aria-describedby="flavours"
                aria-controls="flavours"
                @change="StatusToggleAll"
              >
                All
              </b-form-checkbox>
            </template>

            <template v-slot="{ ariaDescribedby }">
              <b-form-checkbox-group
                v-model="filter.status"
                :options="status"
                textField="name"
                valueField="id"
                :aria-describedby="ariaDescribedby"
                class="ml-4"
                stacked
              ></b-form-checkbox-group>
            </template>
          </b-form-group>
        </template>
      </SideBarFilter>
      <ModalMarketPlace
        :marketplace_channel="marketplace_channel"
        @handleImageClick="handleImageClick"
      />
      <ModalLoading ref="modalLoading" />
      <b-modal id="modal-edit-name">
        <template #modal-header>
          <b-row class="header-filter no-gutters">
            <b-col lg="10" class="ft-title">Shop Name</b-col>
            <b-col class="text-right">
              <button
                type="button"
                aria-label="Close"
                class="close text-right"
                @click="$bvModal.hide('modal-edit-name')"
              >
                ×
              </button>
            </b-col>
          </b-row>
        </template>
        <InputText
          textFloat="Name"
          placeholder="Name"
          type="text"
          name="name"
          isRequired
          v-model="shopName"
          :v="$v.shopName"
          :isValidate="$v.shopName.$error"
        />

        <template #modal-footer class="py-0">
          <div class="d-flex justify-content-between w-100">
            <b-button
              class="btn-cancel btn-width"
              @click="$bvModal.hide('modal-edit-name')"
              >Cancel
            </b-button>
            <b-button class="main-color btn-save" @click="updateShopName()">
              Save
            </b-button>
          </div>
        </template>
      </b-modal>
    </div>
  </div>
</template>
<script>
import InputText from "@/components/inputs/InputText";
import ModalLoading from "@/components/modal/ModalLoading";
import Pagination from "@/components/Pagination";
import HeaderPanel from "@/components/HeaderPanel";
import SideBarFilter from "@/components/SideBarFilter";
import ModalMarketPlace from "@/components/ecommerce/modal";
import OtherLoading from "@/components/loading/OtherLoading";
import { required } from "vuelidate/lib/validators";
export default {
  components: {
    InputText,
    ModalLoading,
    Pagination,
    HeaderPanel,
    SideBarFilter,
    ModalMarketPlace,
    OtherLoading,
  },
  validations: {
    shopName: { required },
  },
  data() {
    return {
      isLoading: true,
      shopping: [{ name: "Shopee", id: "1" }],
      marketplace_channel: [
        "https://aukey.co.th/wp-content/uploads/2020/03/Shopee-logo.png",
        "https://www.blognone.com/sites/default/files/externals/8ad56f147f69abe1d1f8c212baa8ad0f.jpg",
        "https://www.mindphp.com/images/knowledge/line-shop.png",
      ],
      shopping_Selected: [],
      shopping_SelectedAll: false,
      shopping_Indeterminate: false,
      status: [
        { name: "Connected", id: 1 },
        { name: "Not Connected", id: 0 },
      ],
      Status_Selected: [],
      Status_SelectedAll: false,
      Status_Indeterminate: false,
      fields: [
        {
          key: "marketplace",
          label: "Market Place",
          tdClass: "text-center",
          thStyle: "width:20%",
        },
        {
          key: "shopName",
          label: "Shop Name",
        },
        {
          key: "status",
          label: "Status",
          tdClass: "d-flex justify-content-center",
          thStyle: "width:20%",
        },
        {
          key: "action",
          label: "",
          class: "w-100px",
        },
      ],
      items: [],
      rows: 0,
      pageOptions: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      filter: {
        search: "",
        status: [],
        chanel: [],
        page: 1,
        take: 10,
      },
      shopName: "",
      shopId: "",
      isBusy: false,
    };
  },
  mounted() {},

  async created() {
    await this.getMarketPlace();
    await this.getListMarketPlace();
    if (this.$route.query.code) {
      await this.LazadaRegister();
    }
    this.isLoading = false;
  },
  watch: {
    "filter.shopping_channel"(newValue, oldValue) {
      if (newValue.length === 0) {
        this.shopping_Indeterminate = false;
        this.shopping_SelectedAll = false;
      } else if (newValue.length === this.shopping.length) {
        this.shopping_Indeterminate = false;
        this.shopping_SelectedAll = true;
      } else {
        this.shopping_Indeterminate = true;
        this.shopping_SelectedAll = false;
      }
    },
    "filter.status"(newValue, oldValue) {
      if (newValue.length === 0) {
        this.Status_Indeterminate = false;
        this.Status_SelectedAll = false;
      } else if (newValue.length === this.status.length) {
        this.Status_Indeterminate = false;
        this.Status_SelectedAll = true;
      } else {
        this.Status_Indeterminate = true;
        this.Status_SelectedAll = false;
      }
    },
  },
  destroyed() {},
  methods: {
    handleEdit(val) {
      this.$bvModal.show("modal-edit-name");
      this.shopName = val.shopName;
      this.shopId = val.shopId;
    },
    async updateShopName() {
      this.$v.$touch();
      if (this.$v.shopName.$error) return;
      let payload = {
        ShopName: this.shopName,
        ShopId: this.shopId,
      };
      const res = await this.$Axios.put(
        `${process.env.VUE_APP_API}/MarketPlace`,
        payload
      );
      if (res.data.detail) {
        this.$bvModal.hide("modal-edit-name");
        this.successAlert();
        this.getListMarketPlace();
      } else {
        this.errorAlert(res.data.message);
      }
    },
    async LazadaRegister() {
      const res = await this.$Axios(
        `${process.env.VUE_APP_API}/MarketPlace/generate/accessToken?code=${this.$route.query.code}`
      );
      if (res.data.detail == 1) {
        this.getListMarketPlace();
      }
    },
    async getMarketPlace() {
      const res = await this.$Axios(
        `${process.env.VUE_APP_API}/MarketPlace/getchannel`
      );
      this.marketplace_channel = res.data.detail;
    },
    async getListMarketPlace() {
      this.isBusy = true;
      const res = await this.$Axios.post(
        `${process.env.VUE_APP_API}/MarketPlace/getList`,
        this.filter
      );

      this.items = res.data.detail.detail;
      this.rows = res.data.detail.count;
      this.isBusy = false;
    },
    addMarketPlace() {
      this.$bvModal.show("modal-market-place");
    },
    ShoppingToggleAll(checked) {
      this.filter.shopping_channell = checked
        ? this.shopping.map((el) => el.id).slice()
        : [];
    },
    StatusToggleAll(checked) {
      this.filter.status = checked
        ? this.status.map((el) => el.id).slice()
        : [];
    },
    sidebarFilter() {
      this.$refs.sidebarFilter.show();
    },
    handleChangeTake(value) {
      this.filter.take = value;
      this.getListMarketPlace();
    },
    pagination(page) {
      this.filter.page = page;
      this.getListMarketPlace();
    },

    clearFilter() {
      this.filter = {
        search: null,
        page: 1,
        take: 10,
        shopping_channel: [],
        status: [],
      };
    },
    async marketplaceDisconnect(item) {
      if (item.status) {
        const res = await this.$Axios.put(
          `${process.env.VUE_APP_API}/MarketPlace/disconnect?shopId=${item.shopId}`
        );
        this.getListMarketPlace();
      } else {
        return this.lazadaLogin(item.shopId);
      }
    },
    async handleImageClick(val) {
      switch (val.name.toLowerCase()) {
        case "lazada":
          this.lazadaLogin();
          break;
        case "shopee":
          alert("ยังไม่เปิด");
          break;

        default:
          alert("ยังไม่เปิด");
          break;
      }
    },
    async lazadaLogin() {
      const appKey = "111122";
      const appSecret = "sqzDTMSDLTNEGKVFyMWb0QboXUldQvc8";
      const domain = window.location.origin;
      const url = `https://auth.lazada.com/oauth/authorize?response_type=code&force_auth=true&redirect_uri=${domain}/setting/ecommerce&client_id=${appKey}`;
      window.location.href = url;
    },
    async connectShopee() {
      const partner_id = 1008114;
      const date = new Date();
      const redirect_url =
        "https://7950-49-228-235-232.ap.ngrok.io/setting/ecommerce";
      const url = `https://partner.uat.shopeemobile.com/api/v2/shop/auth_partner?partner_id=${partner_id}&redirect=${redirect_url}&timestamp=${
        date.getTime() / 1000
      }`;
      const hex = await this.sha256(url);
      window.location.href = url + "&sign=" + hex;
    },
    arbuf2hex(buffer) {
      var hexCodes = [];
      var view = new DataView(buffer);
      for (var i = 0; i < view.byteLength; i += 4) {
        var value = view.getUint32(i);
        var stringValue = value.toString(16);
        var padding = "00000000";
        var paddedValue = (padding + stringValue).slice(-padding.length);
        hexCodes.push(paddedValue);
      }
      return hexCodes.join("");
    },

    async sha256(hexstr) {
      var buffer = new Uint8Array(
        hexstr.match(/[\da-f]{2}/gi).map(function (h) {
          return parseInt(h, 16);
        })
      );
      return crypto.subtle.digest("SHA-256", buffer).then(async (hash) => {
        return this.arbuf2hex(hash);
      });
    },
  },
};
</script>
<style scoped>
.text-log {
  text-decoration: underline;
  font-size: 65%;
  color: rgb(127, 143, 198);
  cursor: pointer;
}
.justify-content-evenly {
  justify-content: space-evenly;
}
::v-deep .modal-header {
  display: block !important;
  background-color: var(--secondary-color) !important;
}
.ft-title {
  color: var(--font-color);
  font-weight: 600;
  font-size: 16px;
}
label.title {
  color: #212529;
  font-size: 16px;
  margin-bottom: 2px;
  font-weight: bold;
}
.market-place-image-logo {
  width: 50px;
  height: auto;
}
.f-20 {
  font-size: 20px;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff !important;
  background-color: #a59cfc;
}
.tabs-title-spacing {
  /* padding: 0px 15px 0 15px !important; */
  margin: 10px 0px;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}
.btn-fixed {
  min-width: 150px;
}
.my-actions {
  margin: 0 2em;
}

.right-gap {
  margin-right: auto;
}
.dot-status {
  padding: 10px;
  background: black;
  border-radius: 50%;
  margin-right: 5px;
}
</style>
